import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/paste-website/src/layouts/DefaultLayout.tsx";
import { graphql } from 'gatsby';
import { Anchor } from '@twilio-paste/anchor';
import { Callout, CalloutTitle, CalloutText } from '../../../components/callout';
import { DoDont, Do, Dont } from '../../../components/DoDont';
export const pageQuery = graphql`
  {
    mdx(fields: {slug: {eq: "/content/product-style-guide/"}}) {
      fileAbsolutePath
      frontmatter {
        slug
        title
      }
      headings {
        depth
        value
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageAside = makeShortcode("PageAside");
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <content>
      <h1>{props.pageContext.frontmatter.title}</h1>
      <p>{props.pageContext.frontmatter.description}</p>
    </content>
    <hr></hr>
    <contentwrapper>
      <PageAside data={props.data.mdx} mdxType="PageAside" />
      <content>
        <h2>{`Content principles`}</h2>
        <h4>{`Identify your user and speak directly to them`}</h4>
        <ul>
          <li parentName="ul">{`Consider who you're writing for and their technical level. Use words the user is familiar with.`}</li>
          <li parentName="ul">{`Address the user as `}<em parentName="li">{`you`}</em>{`, refer to Twilio as `}<em parentName="li">{`we`}</em>{`.`}</li>
        </ul>
        <h4>{`Write for the reading level of a 10-13 year old`}</h4>
        <ul>
          <li parentName="ul">{`Use simple, short sentences and common words.`}</li>
          <li parentName="ul">{`Explain technical terms and write acronyms in full the first time a user sees them.`}</li>
        </ul>
        <Callout mdxType="Callout">
  <CalloutTitle mdxType="CalloutTitle">Using readability tools</CalloutTitle>
  <CalloutText mdxType="CalloutText">
    Sites offering{' '}
    <Anchor href="https://readabilityformulas.com/free-readability-formula-tests.php" mdxType="Anchor">
      free readability tools like this one
    </Anchor>{' '}
    can be useful for assessing the "reading age" of long content (more than 150 words). However, they're not great at
    testing short UI content that might only contain two or three words. Use them with caution. Look for a simpler word
    whenever you can to improve the readability of your content.
  </CalloutText>
        </Callout>
        <h4>{`Be inclusive of people and devices`}</h4>
        <ul>
          <li parentName="ul">{`Avoid `}<em parentName="li">{`click, tap, touch`}</em>{` instructions as not every device supports touch.`}</li>
          <li parentName="ul">{`Instead, use `}<em parentName="li">{`select`}</em>{` to instruct a user to interact.`}</li>
          <li parentName="ul">{`Avoid relying on directional or color-based cues `}{`—`}{` `}<em parentName="li">{`to the right, the blue button`}</em>{`. These are problematic for responsive products or users who see colors differently.`}</li>
          <li parentName="ul">{`Instead, write `}<em parentName="li">{`the main menu`}</em>{` or `}<em parentName="li">{`select "Apply changes"`}</em>{`. Name the component or area you’re referencing.`}</li>
        </ul>
        <h4>{`Write as if the user is in a rush`}</h4>
        <ul>
          <li parentName="ul">{`Avoid repetition and padding.`}</li>
          <li parentName="ul">{`Phrases we use when writing formally or presenting `}{`—`}{` `}<em parentName="li">{`please note, so, in fact, in other words`}</em>{` `}{`—`}{` should be cut.`}</li>
          <li parentName="ul">{`Chunk content and use headings to help the user find what they need.`}</li>
        </ul>
        <h4>{`Be direct and straightforward`}</h4>
        <ul>
          <li parentName="ul">{`Lead with the reason (why), by front-loading messages with words that address the user’s needs.`}</li>
          <li parentName="ul">{`Leave the user with a clear instruction of how to address that need.`}</li>
          <li parentName="ul">{`Avoid relying on Twilio or industry jargon, idioms, or cultural references to communicate what you mean.`}</li>
          <li parentName="ul">{`Use the words that are most familiar to the user.`}</li>
        </ul>
        <h4>{`It’s ok to bend grammatical rules to sound natural`}</h4>
        <ul>
          <li parentName="ul">{`End sentences with prepositions `}{`—`}{` `}<em parentName="li">{`of, with, for`}</em>{` `}{`—`}{` if it sounds less awkward.`}</li>
          <li parentName="ul">{`And you can start sentences with `}<em parentName="li">{`But`}</em>{` or `}<em parentName="li">{`And`}</em>{`.`}</li>
          <li parentName="ul">{`Use contractions with pronouns `}{`—`}{` `}<em parentName="li">{`it’s, there’s, you’ll, we’ll, you’re`}</em>{` `}{`—`}{` to sound conversational.`}</li>
        </ul>
        <Callout mdxType="Callout">
  <CalloutTitle mdxType="CalloutTitle">Exception: avoid contractions in error messages</CalloutTitle>
  <CalloutText mdxType="CalloutText">
    Avoid contractions in negative scenarios. When a user is stressed, their comprehension suffers. Often users will
    read <em>can’t</em> as <em>can</em>, missing the -’t at the end. Write out error messages or destructive action
    warnings in full.
  </CalloutText>
        </Callout>
        <h2>{`Punctuation`}</h2>
        <h4>{`Ampersands &`}</h4>
        <p><strong parentName="p">{`Avoid using ampersands.`}</strong>{` In exceptional circumstances, if space is unavoidably restricted or it's part of a product/brand name, use "&".`}</p>
        <p><strong parentName="p">{`Do`}</strong>{` use "and" instead of an ampersand (&).`}</p>
        <ul>
          <li parentName="ul"><em parentName="li">{`Voice and messaging`}</em></li>
          <li parentName="ul"><em parentName="li">{`Create and submit a Regulatory Bundle.`}</em></li>
          <li parentName="ul"><em parentName="li">{`Messages sent from AT&T to Twilio numbers are not impacted.`}</em></li>
        </ul>
        <p><strong parentName="p">{`Don't`}</strong>{` use "&" just to make content shorter. If space is an issue, address the content and design together.`}</p>
        <Callout mdxType="Callout">
  <CalloutTitle mdxType="CalloutTitle">Why?</CalloutTitle>
  <CalloutText mdxType="CalloutText">
    {' '}
    Using "and" rather than the ampersand symbol (&) supports localization and users with lower literacy or cognitive
    impairments. Some screen readers can’t interpret the code behind an ampersand, which obscures the meaning for the
    user.
  </CalloutText>
        </Callout>
        <hr></hr>
        <h4>{`Apostrophes '`}</h4>
        <p><strong parentName="p">{`Do`}</strong>{` use an apostrophe to indicate possession or belonging.`}</p>
        <ul>
          <li parentName="ul">{`The APIs of Twilio > Twilio's APIs`}<ul parentName="li">
              <li parentName="ul"><em parentName="li">{`The phone number's prefix.`}</em></li>
              <li parentName="ul"><em parentName="li">{`Choose from one of SendGrid's responsive email designs.`}</em></li>
            </ul></li>
        </ul>
        <p><strong parentName="p">{`Do`}</strong>{` use an apostrophe to indicate missing letters.`}</p>
        <ul>
          <li parentName="ul">{`You are > you're`}<ul parentName="li">
              <li parentName="ul"><em parentName="li">{`You're not logged in.`}</em></li>
            </ul></li>
        </ul>
        <p><strong parentName="p">{`Don't`}</strong>{` use apostrophes to make a plural. We should never see `}<em parentName="p">{`Upgrade to buy phone number's`}</em>{`, `}<em parentName="p">{`Install plugin's`}</em>{`, or `}<em parentName="p">{`SID's`}</em>{`.`}</p>
        <hr></hr>
        <h4>{`Commas ,`}</h4>
        <p><strong parentName="p">{`Do`}</strong>{` use the serial or 'Oxford' comma in a list of 3 or more items.`}</p>
        <ul>
          <li parentName="ul"><em parentName="li">{`You can customize the agent desktop, channels, interaction routing, and reporting.`}</em></li>
        </ul>
        <hr></hr>
        <h4>{`Exclamation marks !`}</h4>
        <p><strong parentName="p">{`Do`}</strong>{` use a single exclamation mark to support a celebration. But never more than one at a time (!!)`}</p>
        <ul>
          <li parentName="ul"><em parentName="li">{`Congratulations, you sent your first email!`}</em></li>
        </ul>
        <p><strong parentName="p">{`Don't`}</strong>{` use exclamation marks to draw attention to or emphasise an error message or alert.
Exclamation marks can increase anxiety or sound like shouting. Unless it's a clear moment of success, don't use them.`}</p>
        <hr></hr>
        <h4>{`Hyphens and dashes - `}{`–`}{` `}{`—`}</h4>
        <p><strong parentName="p">{`Do`}</strong>{` use hyphens (-) to create compound words.`}</p>
        <ul>
          <li parentName="ul">{`Hyphens never have a space either side.`}<ul parentName="li">
              <li parentName="ul"><em parentName="li">{`Add-on feature`}</em></li>
            </ul></li>
        </ul>
        <p><strong parentName="p">{`Do`}</strong>{` use en-dashes (`}{`–`}{`) indicate a range of values (time, dates, numerical amounts.)`}</p>
        <ul>
          <li parentName="ul">{`En-dashes never have a space either side.`}<ul parentName="li">
              <li parentName="ul"><em parentName="li">{`0-50 agents`}</em></li>
              <li parentName="ul">{`Tip: If your system does not support en-dashes, use a hyphen to indicate range.`}</li>
            </ul></li>
        </ul>
        <p><strong parentName="p">{`Avoid`}</strong>{` using em-dashes (`}{`—`}{`) to create an emphatic pause.`}</p>
        <ul>
          <li parentName="ul">{`Tip: It's ok to write a very short sentence to emphasise its meaning.
We might use em-dashes with friends and colleagues in email and chat to signify an added thought, but the user needs clarity over what's important.
Give the thought a sentence or restructure your syntax.`}</li>
        </ul>
        <hr></hr>
        <h4>{`Parentheses ( )`}</h4>
        <p><strong parentName="p">{`Do`}</strong>{` use parentheses to provide an example or explanation.`}</p>
        <ul>
          <li parentName="ul"><em parentName="li">{`Password must contain a special character (&, @, %, \\$, ^)`}</em></li>
        </ul>
        <p><strong parentName="p">{`Do`}</strong>{` use parentheses to note an acronym the first time a user encounters it.`}</p>
        <ul>
          <li parentName="ul"><em parentName="li">{`Transport Layer Security (TLS) is a mechanism for securing your SIP connections.`}</em><ul parentName="li">
              <li parentName="ul">{`In this example, the user is familiar with the term "SIP" but not "TLS."`}</li>
            </ul></li>
        </ul>
        <hr></hr>
        <h4>{`Periods .`}</h4>
        <p><strong parentName="p">{`Do`}</strong>{` end every full sentence with a period.`}</p>
        <ul>
          <li parentName="ul"><em parentName="li">{`Enable Flex Dialpad in Console.`}</em></li>
          <li parentName="ul"><em parentName="li">{`Sign up for a free account.`}</em></li>
        </ul>
        <p><strong parentName="p">{`Don't`}</strong>{` use periods at the end of fragments or incomplete sentences.`}</p>
        <p><strong parentName="p">{`Don't`}</strong>{` use periods at the end of an anchor link if it is the last line on an alert or notification.`}</p>
        <hr></hr>
        <h4>{`Quotation marks " "`}</h4>
        <p><strong parentName="p">{`Do`}</strong>{` use quotation marks to pick out words from a component or a file name.`}</p>
        <ul>
          <li parentName="ul"><em parentName="li">{`Select "Apply changes"`}</em></li>
          <li parentName="ul"><em parentName="li">{`We recommend using dedicated accounts for your development environments, like "Flex Production" and "Flex Development."`}</em></li>
        </ul>
        <p><strong parentName="p">{`Do`}</strong>{` put punctuation inside quotation marks, if necessary.`}</p>
        <ul>
          <li parentName="ul"><em parentName="li">{`To create a new project, select "Create My Flex Project."`}</em></li>
        </ul>
        <p><strong parentName="p">{`Don't`}</strong>{` use quotation marks to refer to products or features.
Products and features are proper nouns and should be capitalized to differentiate them.`}</p>
        <hr></hr>
        <h4>{`Semicolons ;`}</h4>
        <p><strong parentName="p">{`Avoid`}</strong>{` using semicolons. In formal writing, semicolons are useful. In product content, they can be messy and confusing.`}</p>
        <ul>
          <li parentName="ul">{`If you feel your sentence needs a semicolon, simplify it or create two sentences.`}</li>
        </ul>
        <p><strong parentName="p">{`Do`}</strong>{` use punctuation and conjunctions (`}<em parentName="p">{`and`}</em>{`, `}<em parentName="p">{`but`}</em>{`, `}<em parentName="p">{`so`}</em>{`) to separate thoughts.`}</p>
        <ul>
          <li parentName="ul">{`List complex items that already contain a comma using bullet points. For example, state capitals`}<ul parentName="li">
              <li parentName="ul"><em parentName="li">{`Montgomery, Alabama`}</em></li>
              <li parentName="ul"><em parentName="li">{`Juneau, Alaska`}</em></li>
              <li parentName="ul"><em parentName="li">{`Phoenix, Arizona`}</em></li>
            </ul></li>
        </ul>
        <hr></hr>
        <h2>{`Grammar and formatting`}</h2>
        <h4>{`Abbreviations`}</h4>
        <p><strong parentName="p">{`Do`}</strong>{` use common abbreviations if space is limited, such as in form headings.`}</p>
        <ul>
          <li parentName="ul"><em parentName="li">{`Ref.`}</em>{` in place of `}<em parentName="li">{`Reference`}</em></li>
          <li parentName="ul"><em parentName="li">{`No.`}</em>{` or `}<em parentName="li">{`#`}</em>{` in place of `}<em parentName="li">{`number`}</em></li>
          <li parentName="ul"><em parentName="li">{`Info`}</em>{` in place of `}<em parentName="li">{`information`}</em></li>
        </ul>
        <p><strong parentName="p">{`Do`}</strong>{` use a period at the end of the abbreviation if the last letter is not the same as the full word's last letter.`}</p>
        <ul>
          <li parentName="ul"><em parentName="li">{`Ref.`}</em>{` in place of reference`}</li>
          <li parentName="ul"><em parentName="li">{`Info`}</em>{` in place of information`}</li>
        </ul>
        <p><em parentName="p">{`Avoid`}</em>{` using abbreviations in body copy. It might reduce the character count but it can increase cognitive load and reduce comprehension.`}</p>
        <hr></hr>
        <h4>{`Acronyms`}</h4>
        <p><strong parentName="p">{`Do`}</strong>{` write the phrase in full, followed by the acronym in parentheses the first time a user encounters it.`}</p>
        <ul>
          <li parentName="ul"><em parentName="li">{`Transport Layer Security (TLS) is a mechanism for securing your SIP connections.`}</em><ul parentName="li">
              <li parentName="ul">{`In this example, the user is familiar with the term "SIP" but not "TLS."`}</li>
            </ul></li>
        </ul>
        <hr></hr>
        <h4>{`American English`}</h4>
        <p><strong parentName="p">{`Do`}</strong>{` use American English spellings throughout products.`}</p>
        <ul>
          <li parentName="ul"><em parentName="li">{`Behavior, dialed, color, artifact, center, license`}</em>{` (for both verb and noun), `}<em parentName="li">{`-ize`}</em>{` endings, `}<em parentName="li">{`program`}</em>{` (for all meanings)`}</li>
        </ul>
        <hr></hr>
        <h4>{`Bold and italics`}</h4>
        <h5>{`Bold`}</h5>
        <p><strong parentName="p">{`Do`}</strong>{` use bold type sparingly, to draw attention to the most important word, number, or short phrase in your copy.`}</p>
        <ul>
          <li parentName="ul">{`Bold type can help a user scan the page more effectively by making landmarks more prominent.`}</li>
        </ul>
        <p><strong parentName="p">{`Do`}</strong>{` consider using bold type to refer to UI elements in instructional content. E.g.`}</p>
        <ul>
          <li parentName="ul"><em parentName="li">{`Select the location from `}<strong parentName="em">{`Region/State`}</strong>{`.`}</em></li>
          <li parentName="ul"><em parentName="li">{`Select `}<strong parentName="em">{`Continue`}</strong>{` to review your order.`}</em></li>
        </ul>
        <p><strong parentName="p">{`Don't`}</strong>{` use bold multiple times in body copy or for a whole sentence/paragraph. It dilutes its effect.`}</p>
        <ul>
          <li parentName="ul">{`Only `}<strong parentName="li">{`bold the most important information the user needs to retain`}</strong>{` and only use it if user testing indicates a need.`}</li>
          <li parentName="ul">{`Consider if putting an important sentence on a new line will do a better job of making it stand out than making it bold.`}</li>
        </ul>
        <h5>{`Italics`}</h5>
        <p><strong parentName="p">{`Avoid`}</strong>{` using italic type wherever possible in product content.`}</p>
        <ul>
          <li parentName="ul">{`If you need to draw attention to a word, use bold type.`}</li>
          <li parentName="ul">{`If you need to quote a phrase, use quotation marks "like this."`}</li>
          <li parentName="ul">{`While you may see italics used elsewhere at Twilio, in products let's keep it simple and avoid it.`}</li>
        </ul>
        <hr></hr>
        <h4>{`Capitalization`}</h4>
        <p><strong parentName="p">{`Do`}</strong>{` capitalize proper nouns – products, people, places.`}</p>
        <ul>
          <li parentName="ul">{`Products include features, programs, or systems that Twilio has given a specific name to.`}</li>
          <li parentName="ul">{`If a brand or product name uses a mix of upper and lower case (TwiML, eBay) use the name's convention.`}</li>
          <li parentName="ul">{`People are named individuals or identifiable roles.`}</li>
        </ul>
        <p><strong parentName="p">{`Don't`}</strong>{` capitalize general terms (`}<em parentName="p">{`email, contact center, platform, phone number`}</em>{`)`}</p>
        <ul>
          <li parentName="ul">{`Tip: If it's something Twilio has created or owns, it takes a capital.`}</li>
        </ul>
        <hr></hr>
        <h4>{`Dates`}</h4>
        <p><strong parentName="p">{`Do`}</strong>{` write dates so that they are easy to scan and sound human to read aloud.`}</p>
        <ul>
          <li parentName="ul"><em parentName="li">{`Day, Month DD, YYYY`}</em>{` (`}<em parentName="li">{`Sunday, May 17, 2020`}</em>{`) `}{`—`}{` when referencing a specific day and the year is not clear from the context.`}</li>
          <li parentName="ul"><em parentName="li">{`Month DD, YYYY`}</em>{` (`}<em parentName="li">{`May 17, 2020`}</em>{`) `}{`—`}{` when the day of the week is unimportant or does not help the user scan.`}</li>
          <li parentName="ul"><em parentName="li">{`Month YYYY`}</em>{` (`}<em parentName="li">{`May 2020`}</em>{`) `}{`—`}{` when only the month is relevant. Useful in subheads to organize chronological lists.`}</li>
        </ul>
        <p><strong parentName="p">{`Avoid`}</strong>{` repeating information. If a history or log organizes its contents using `}<em parentName="p">{`Month YYYY`}</em>{` sub-headings, the following line items should use `}<em parentName="p">{`Day DD`}</em>{`.`}</p>
        <ul>
          <li parentName="ul">{`For the subhead `}<em parentName="li">{`January 2020`}</em>{`, the dates that follow would read `}<em parentName="li">{`Wednesday 15`}</em>{`, `}<em parentName="li">{`Tuesday 28`}</em>{` and so on.`}</li>
        </ul>
        <p><strong parentName="p">{`Do`}</strong>{` indicate a date range by using `}<em parentName="p">{`to`}</em>{` between values.`}</p>
        <ul>
          <li parentName="ul">{`_January 24 to February 3, 2020`}</li>
          <li parentName="ul"><em parentName="li">{`January 24 to 28`}</em></li>
        </ul>
        <p><strong parentName="p">{`Do`}</strong>{` abbreviate months to their first 3 letters if space is limited.`}</p>
        <ul>
          <li parentName="ul">
            <p parentName="li"><em parentName="p">{`Jan, Feb, Mar`}</em></p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Tip: If it's absolutely necessary to cite a specific date numerically, use the `}<a parentName="p" {...{
                "href": "https://www.w3.org/TR/NOTE-datetime#:~:text=Introduction-,The%20International%20Standard%20for%20the%20representation%20of%20dates%20and%20times,Representation%20of%20dates%20and%20times%22."
              }}>{`ISO 8601 date format`}</a>{` YYYY-MM-DD But note that this may not be useful to your user. Always consider which format is quickest to scan and easiest to read aloud.`}</p>
          </li>
        </ul>
        <hr></hr>
        <h4>{`I/You/We/Us`}</h4>
        <p><strong parentName="p">{`Do`}</strong>{` refer to the user as `}<em parentName="p">{`you`}</em>{` and to Twilio as `}<em parentName="p">{`we`}</em>{`.`}</p>
        <ul>
          <li parentName="ul">{`Tip: If the user has agency or ownership, use ‘I’ or ‘my’.`}<ul parentName="li">
              <li parentName="ul"><em parentName="li">{`If you still can’t connect, contact our support team.`}</em></li>
              <li parentName="ul"><em parentName="li">{`To access billing information, select My Account.`}</em></li>
              <li parentName="ul"><em parentName="li">{`I understand.`}</em>{` (This usage is almost exclusively for legal consent.)`}</li>
            </ul></li>
        </ul>
        <Callout mdxType="Callout">
  <CalloutTitle as="h4" mdxType="CalloutTitle">Legal content</CalloutTitle>
  <CalloutText mdxType="CalloutText">
    Legal content follows different rules to product &mdash; e.g., in Terms of Service, Twilio is usually referenced in
    the third person. Always check with the legal team if the content regards consent.
  </CalloutText>
        </Callout>
        <p><strong parentName="p">{`Do`}</strong>{` say `}<em parentName="p">{`we`}</em>{` instead of `}<em parentName="p">{`Twilio`}</em></p>
        <ul>
          <li parentName="ul">{`It's us talking, so there's no need to refer to us in the third person.`}</li>
          <li parentName="ul">{`If "Twilio" is included in a product or feature name, that's ok as that's using it as a noun.`}</li>
        </ul>
        <hr></hr>
        <h4>{`Lists (all)`}</h4>
        <p><strong parentName="p">{`Do`}</strong>{` start each list item with the same part of speech – usually a noun or verb – to make the list "parallel."`}</p>
        <ul>
          <li parentName="ul">{`Punctuate the end of each item with a period if it is a complete sentence.`}</li>
          <li parentName="ul">{`Be consistent within the list. If one item takes a full point, all items should.`}</li>
        </ul>
        <p><strong parentName="p">{`Don't`}</strong>{` punctuate the end of an item in a vertical list with a comma or ellipsis "..."`}</p>
        <h4>{`Lists (bullet points)`}</h4>
        <p><strong parentName="p">{`Do`}</strong>{` use bulleted lists for items linked by a topic. Make the list topic clear in the sentence or heading that precedes it.`}</p>
        <h4>{`Lists (numbered)`}</h4>
        <p><strong parentName="p">{`Do`}</strong>{` use numbered lists for items to be done or understood in a sequence.`}</p>
        <p><strong parentName="p">{`Avoid`}</strong>{` listing more than 7 items `}{`—`}{` it's a lot to take in. Break a big lists into smaller ones with explanatory headings.`}</p>
        <h4>{`Lists (sentence)`}</h4>
        <p><strong parentName="p">{`Do`}</strong>{` use a sentence list if you’re explaining a short process, noting up to four options, or highlighting up to four key points. You just read a sentence list.`}</p>
        <ul>
          <li parentName="ul">{`Sentence lists should be used for no more than four items. Over four? List with bullet points.`}</li>
          <li parentName="ul">{`Use the Oxford comma before the last item in a sentence list.`}</li>
        </ul>
        <hr></hr>
        <h4>{`Numbers`}</h4>
        <p><strong parentName="p">{`Do`}</strong>{` use precise numbers to express statistics, metrics, or quantities. Avoid rounding up or down when the user is depending on the information to be accurate.`}</p>
        <p><strong parentName="p">{`Do`}</strong>{` use numerals (1, 2, 3, 45, 101) instead of spelling out the number.`}</p>
        <ul>
          <li parentName="ul">{`Percentages `}<em parentName="li">{`49%`}</em></li>
        </ul>
        <p><strong parentName="p">{`Do`}</strong>{` write expressions that use ordinal numbers in words.`}</p>
        <ul>
          <li parentName="ul"><em parentName="li">{`One to watch, thousands of options`}</em></li>
          <li parentName="ul"><em parentName="li">{`First, create a profile`}</em></li>
        </ul>
        <p><strong parentName="p">{`Do`}</strong>{` use both words and numerals to represent numbers over 1000, unless the user needs a specific number.`}</p>
        <ul>
          <li parentName="ul"><em parentName="li">{`Up to 40k emails`}</em></li>
          <li parentName="ul"><em parentName="li">{`More than $1.5B`}</em></li>
          <li parentName="ul"><strong parentName="li">{`Note`}</strong>{` this is most commonly used in marketing content — make sure you're giving the user what they need to complete a task, and not overloading them with extra info.`}</li>
        </ul>
        <p><strong parentName="p">{`Do`}</strong>{` use a space between the number and its unit of measurement or its order of magnitude if this is written out in words.`}</p>
        <ul>
          <li parentName="ul"><em parentName="li">{`40 GB, 564 customers`}</em></li>
          <li parentName="ul"><em parentName="li">{`More than $1.3 billion, 45 million customers`}</em></li>
        </ul>
        <p><strong parentName="p">{`Do`}</strong>{` use commas in numbers that are four or more digits.`}</p>
        <ul>
          <li parentName="ul"><em parentName="li">{`1,365 messages sent`}</em></li>
        </ul>
        <p><strong parentName="p">{`Don't`}</strong>{` use commas in error codes, account numbers, or phone numbers.`}</p>
        <hr></hr>
        <h4>{`Sentence case`}</h4>
        <p><strong parentName="p">{`Do`}</strong>{` use sentence case everywhere, even in H1s, CTAs, and navigation. Sentence case makes content feel more human and it's generally easier to scan.`}</p>
        <ul>
          <li parentName="ul">{`This is sentence case. (Use this.)`}</li>
          <li parentName="ul">{`This is Title Case. (Rarely use this, and only if directed.)`}</li>
          <li parentName="ul">{`THIS IS ALL CAPS. (Don’t use this.)`}</li>
        </ul>
        <hr></hr>
        <h4>{`Time`}</h4>
        <p><strong parentName="p">{`Do`}</strong>{` write times so that they are easy to scan and sound human to read aloud. Usually, this means using the 12hr clock, with am/pm modifiers.`}</p>
        <ul>
          <li parentName="ul"><em parentName="li">{`12:25am`}</em></li>
          <li parentName="ul"><em parentName="li">{`3:32pm`}</em></li>
        </ul>
        <hr></hr>
        <h2>{`Common errors`}</h2>
        <h4>{`e.g./i.e.`}</h4>
        <ul>
          <li parentName="ul"><em parentName="li">{`e.g.`}</em>{` = for example`}<ul parentName="li">
              <li parentName="ul"><em parentName="li">{`Create a memorable name for your Flex Project, e.g. "Flex Training 2020."`}</em></li>
            </ul></li>
          <li parentName="ul"><em parentName="li">{`i.e.`}</em>{` = that is`}<ul parentName="li">
              <li parentName="ul">{`Tip: `}<em parentName="li">{`i.e.`}</em>{` usually precedes a simpler way of saying something. Say it simply the first time. Avoid writing `}<em parentName="li">{`i.e.`}</em>{` if you can.`}</li>
            </ul></li>
        </ul>
        <hr></hr>
        <h4>{`Every day/everyday`}</h4>
        <ul>
          <li parentName="ul"><em parentName="li">{`Every day`}</em>{` = each day`}<ul parentName="li">
              <li parentName="ul"><em parentName="li">{`Twilio supports thousands of customers every day.`}</em></li>
            </ul></li>
          <li parentName="ul"><em parentName="li">{`Everyday`}</em>{` = ordinary/usual`}<ul parentName="li">
              <li parentName="ul"><em parentName="li">{`Twilio supports everyday customer needs, like call routing.`}</em></li>
            </ul></li>
        </ul>
        <hr></hr>
        <h4>{`It's/its`}</h4>
        <ul>
          <li parentName="ul"><em parentName="li">{`It's`}</em>{` = contraction of "it"+"is"`}<ul parentName="li">
              <li parentName="ul"><em parentName="li">{`It's now possible to view real-time queue stats.`}</em></li>
              <li parentName="ul">{`Tip: Can you say `}<em parentName="li">{`it is`}</em>{` instead of the word you want? Does the sentence make sense? You want `}<em parentName="li">{`it's`}</em>{`.`}</li>
            </ul></li>
          <li parentName="ul"><em parentName="li">{`Its`}</em>{` = possessive pronoun (belonging to it)`}<ul parentName="li">
              <li parentName="ul"><em parentName="li">{`The company and its customers.`}</em></li>
            </ul></li>
        </ul>
        <hr></hr>
        <h4>{`Log in/login`}</h4>
        <ul>
          <li parentName="ul"><em parentName="li">{`Log in`}</em>{` = verb`}<ul parentName="li">
              <li parentName="ul"><em parentName="li">{`To access your account, please log in.`}</em></li>
            </ul></li>
          <li parentName="ul"><em parentName="li">{`Login`}</em>{` = noun/adjective`}<ul parentName="li">
              <li parentName="ul"><em parentName="li">{`Enter your login details.`}</em></li>
            </ul></li>
        </ul>
        <hr></hr>
        <h4>{`Set up/setup`}</h4>
        <ul>
          <li parentName="ul"><em parentName="li">{`Set up`}</em>{` = verb`}<ul parentName="li">
              <li parentName="ul"><em parentName="li">{`Set up your profile.`}</em></li>
            </ul></li>
          <li parentName="ul"><em parentName="li">{`Setup`}</em>{` = noun/adjective`}<ul parentName="li">
              <li parentName="ul"><em parentName="li">{`System setup information can be found in the user guide.`}</em></li>
            </ul></li>
        </ul>
        <hr></hr>
        <h4>{`Sign up/sign-up`}</h4>
        <ul>
          <li parentName="ul"><em parentName="li">{`Sign up`}</em>{` = verb`}<ul parentName="li">
              <li parentName="ul"><em parentName="li">{`Sign up for a free account.`}</em></li>
            </ul></li>
          <li parentName="ul">{`Sign-up = noun/adjective`}<ul parentName="li">
              <li parentName="ul"><em parentName="li">{`Visit the sign-up page for more information.`}</em></li>
            </ul></li>
        </ul>
        <hr></hr>
        <h4>{`Their/there/they're`}</h4>
        <ul>
          <li parentName="ul"><em parentName="li">{`Their`}</em>{` = plural possessive pronoun (belonging to them)`}<ul parentName="li">
              <li parentName="ul"><em parentName="li">{`The main priority for agents is reducing their wrap-up time.`}</em></li>
            </ul></li>
          <li parentName="ul"><em parentName="li">{`There`}</em>{` = adverb meaning existing in/at that place`}<ul parentName="li">
              <li parentName="ul"><em parentName="li">{`There is a new version available.`}</em></li>
            </ul></li>
          <li parentName="ul"><em parentName="li">{`They're`}</em>{` = contraction of "they"+"are"`}<ul parentName="li">
              <li parentName="ul"><em parentName="li">{`Users are important. They're our top priority.`}</em></li>
            </ul></li>
        </ul>
        <hr></hr>
        <h4>{`Who/that`}</h4>
        <ul>
          <li parentName="ul"><em parentName="li">{`Who`}</em>{` = refers to people`}<ul parentName="li">
              <li parentName="ul"><em parentName="li">{`Team leads who manage multiple business areas can use queues to assign tasks.`}</em></li>
            </ul></li>
          <li parentName="ul"><em parentName="li">{`That`}</em>{` = refers to things`}<ul parentName="li">
              <li parentName="ul"><em parentName="li">{`Companies that require security should enable Secure Media.`}</em></li>
            </ul></li>
        </ul>
      </content>
    </contentwrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      